import React, { FC, useMemo } from 'react';
import { Typography } from '@rubin-dev/goblin';
import { useTranslation } from 'react-i18next';
import {
  Network,
  useExplorerRiskQuery,
  useExplorerClusterForAddressQuery,
  useExplorerAddressQuery,
  useExplorerAddressActivityQuery,
} from '@apolloGenerated';
import { RiskDot } from '@component/NewRisk';
import { Link } from 'react-justanother-router';
import { RouterName } from '../../../../router';
import { getRiskColor } from '@helpers/address';
import sdk from '@aml/sdk';

import cls from './style.module.scss';
import { setFormatDate } from '@utils/timestamp';

type TransactionMainInfoProps = {
  address: string;
  network: Network;
  hideRisk?: boolean;
};

export const TransactionMainInfo: FC<TransactionMainInfoProps> = ({
  network,
  address,
}) => {
  const { t } = useTranslation();

  const setLink = (wid?: string | undefined, owner?: string | null): JSX.Element => {
    if (!wid) return <></>;
    return (
      <Link
        to={RouterName.ExplorerWallet}
        params={{ wid: String(wid), network }}
        target={'_blank'}
      >
        <Typography variant="body-14" color={'primary-1'}>
          {!owner ? 'Entity' : owner}
        </Typography>
      </Link>
    );
  };

  const variables = {
    network,
    address,
  };

  const { data: categoryDict } = sdk.risk.BTC.queries.useRiskServiceCurrentDict();

  // RISK
  const { data: riskData } = useExplorerRiskQuery({
    variables,
  });
  const risk = Number(riskData?.explorerRisk?.risk?.risk);
  const reported = riskData?.explorerRisk.risk?.reported?.name || undefined;

  // CLUSTER
  const { data: clusterData } = useExplorerClusterForAddressQuery({
    variables,
  });

  const cluster = clusterData?.explorerClusterForAddress?.cluster;

  // OWNER
  const { data: addressDetails } = useExplorerAddressQuery({
    variables,
  });
  const owner = addressDetails?.explorerAddress?.owner;

  const categoryName = useMemo(() => {
    const findCategory = Object.values(categoryDict?.params || {})?.find(
      (category) => category.number === cluster?.riskCategory,
    );
    return findCategory ? findCategory.name : '';
  }, [categoryDict, cluster]);

  // ACTIVITY
  const { data } = useExplorerAddressActivityQuery({
    variables,
  });
  const activity = data?.explorerAddressActivity?.activity;

  return (
    <div className={cls.wrapper}>
      {risk && (
        <div className={cls.group}>
          <Typography variant="body-14" color="on-surface-primary-1">
            {t('strings.riskscore')}
          </Typography>
          <Typography variant="body-14" className={cls.risk}>
            <RiskDot risk={Number(risk)} size={12} />
            {risk}%
          </Typography>
        </div>
      )}

      {cluster && (
        <div className={cls.group}>
          <Typography variant="body-14" color="on-surface-primary-1">
            {t('mention.cluster')}
          </Typography>
          <Typography variant="body-14" className={cls.risk}>
            {setLink(cluster?.id, cluster?.owner)}
          </Typography>
        </div>
      )}

      {(reported || categoryName) && (
        <div className={cls.group}>
          <Typography variant="body-14" color="on-surface-primary-1">
            {t(reported ? 'strings.reported' : 'links.category')}
          </Typography>
          <Typography variant="body-14" style={{ color: getRiskColor(risk) }}>
            {reported || categoryName}
          </Typography>
        </div>
      )}

      {owner && (
        <div className={cls.group}>
          <Typography variant="body-14" color="on-surface-primary-1">
            {t('strings.owner')}
          </Typography>
          <Typography variant="body-14" color="error-2">
            {owner}
          </Typography>
        </div>
      )}

      {activity && (
        <div className={cls.group}>
          <Typography variant="body-14" color="on-surface-primary-1">
            {t('titles.activity')}
          </Typography>
          <Typography variant="body-14" color="on-surface-primary-1">
            {setFormatDate(activity.first)}-{setFormatDate(activity.last)}
          </Typography>
        </div>
      )}
    </div>
  );
};
