import React, { FC } from 'react';
import { AddressTransactionsCard } from '../AddressTransactionsCard/AddressTransactionsCard';
import { AddressBalanceCard } from '../AddressBalanceCard/AddressBalanceCard';
import { Network } from '@apolloGenerated';
import styles from './styles.module.scss';
import { TransactionMainInfo } from '@component/Address/ui/TransactionMainInfo/TransactionMainInfo';
import { NetworkIconEnum } from '@shared/ui';

type AddressInfoProps = {
  address: string;
  network: Network;
  currentCurrency: Network | NetworkIconEnum;
  isAsset: boolean;
};
export const AddressInfo: FC<AddressInfoProps> = ({
  address,
  network,
  currentCurrency,
  isAsset,
}) => {
  return (
    <div className={styles.addressInfo}>
      <TransactionMainInfo address={address} network={network} />
      <AddressBalanceCard
        address={address}
        network={network}
        isSimpleView
        currentCurrency={currentCurrency}
        isAsset={isAsset}
      />
      <AddressTransactionsCard address={address} network={network} isSimpleView />
    </div>
  );
};
