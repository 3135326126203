import { FC, useMemo, useState } from 'react';
import {
  Calendar,
  LoaderOverlay,
  Spacer,
  TableController,
  Toggle,
} from '@rubin-dev/goblin';
import {
  renderCellUTXO,
  SortParams,
  TABLE_TRANSACTION_TABS,
  Transaction,
  useSidebarData,
  useSidebarEvents,
  useSidebarTrTabs,
} from '@graph-sidebar-module/shared';
import { TableType } from '@graph/types';
import {
  AddressTransaction,
  AddressTransactionsFilterTxType,
  AddressTransactionsOrderField,
  Network,
  OrderDirection,
  useExplorerFindTransactionsByAddressQuery,
  useGraphStatsAddressByHashQuery,
} from '@apolloGenerated';
import {
  NetworkIconEnum,
  renderCellAmount,
  renderCellCheckbox,
  renderCellChevron,
  renderCellHash,
  renderCellToken,
} from '@shared/ui';
import { headers, INIT_SORT, INIT_SORT_BY, PAGE_SIZE } from '../../conts';
import { AddressTable } from '@graph/models';
import { useCurrencyList } from '@hooks/useCurrencyList';
import { BigNumber } from 'bignumber.js';
import { setFormatDate } from '@utils/timestamp';
import cls from './style.module.scss';
import { DateTime } from 'luxon';

type AddressTransactionTableProps = {
  currentCurrency: Network | NetworkIconEnum;
  isAsset: boolean;
};

export const AddressTransactionsTable: FC<AddressTransactionTableProps> = ({
  isAsset,
  currentCurrency,
}) => {
  const { network, hash, checked } = useSidebarData();
  const { onChangeChecked, onClickChevron } = useSidebarEvents();
  const [tab, setTab] = useSidebarTrTabs();
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState<SortParams>({
    sort: INIT_SORT,
    sortBy: INIT_SORT_BY,
  });
  const [date, setDate] = useState<DateTime[]>([]);

  const { currencies } = useCurrencyList();

  const { data: statsData } = useGraphStatsAddressByHashQuery({
    variables: {
      network,
      address: hash,
    },
  });

  const { data, previousData, loading } = useExplorerFindTransactionsByAddressQuery({
    variables: {
      network,
      filter: {
        address: hash,
        page,
        order: sort.sort
          ? {
              field: sort.sortBy as AddressTransactionsOrderField,
              direction: sort.sort as OrderDirection,
            }
          : null,
        txType: tab,
        timestampFilter: {
          to: date[0]?.toMillis(),
          from: date[1]?.toMillis(),
        },
        pageSize: PAGE_SIZE,
      },
    },
  });

  const renderCallCluster = () => {};

  const rowTemplate = (transaction: AddressTransaction) => {
    let checkboxRender: JSX.Element | string;
    const item = new Transaction(transaction, network, TableType.Address, true);
    const checkedItem = AddressTable.getCheckedItem(
      item.txid,
      item.type,
      item.sourceAddress!,
      item.targetAddress!,
    );
    if (AddressTable.hasChecked(checked, checkedItem)) item.setChecked(true);

    if (item.isUTXO) checkboxRender = renderCellUTXO();
    else if (item.sourceAddress === item.targetAddress) checkboxRender = ' ';
    else if (item.isDirectTransfer)
      checkboxRender = renderCellCheckbox({
        checked: item.checked,
        onChange: (val) => onChangeChecked(val, item),
      });
    else checkboxRender = renderCellChevron({ onClick: () => onClickChevron(item) });

    const amountUsdt = String(
      BigNumber(item.amount!).multipliedBy(currencies[item.token.toLowerCase()]),
    );

    let amount = amountUsdt;

    if (currentCurrency !== NetworkIconEnum.Usdt && isAsset) {
      amount = String(
        BigNumber(amountUsdt).dividedBy(currencies[currentCurrency.toLowerCase()]),
      );
    }

    return {
      hashUniq: AddressTable.getCheckedHash(checkedItem),
      checkbox: checkboxRender,
      timestamp: setFormatDate(Number(item.timestamp)),
      hash: renderCellHash({
        hash: item.txid,
        type: 'transaction',
        network,
        longer: true,
        alias: item.txid.slice(0, 8) + '...',
      }),
      token: renderCellToken({ token: item.token }),
      amount: renderCellAmount({
        amount,
        network,
        type: item.type,
      }),
      receiving: item.recvAddr?.cluster
        ? renderCellHash({
            hash: item.recvAddr.cluster.id,
            type: 'wallet',
            network,
            longer: true,
            alias: item.recvAddr.cluster.id.slice(0, 8) + '...',
          })
        : '-',
      counterparty:
        item.type === AddressTransactionsFilterTxType.Receives &&
        item.recvAddr?.cluster?.owner
          ? renderCellHash({
              hash: item.recvAddr.cluster.id,
              type: 'wallet',
              network,
              longer: true,
              alias: item.recvAddr.cluster.owner,
            })
          : '-',
    };
  };

  const items = useMemo(() => {
    return (
      data?.explorerFindTransactionsByAddress.edge ||
      previousData?.explorerFindTransactionsByAddress.edge ||
      []
    ).map(rowTemplate);
  }, [data, rowTemplate]);

  const stats = statsData?.explorerAddressTransactionStats?.stats;
  const total = useMemo(
    () =>
      tab === AddressTransactionsFilterTxType.All
        ? stats?.total
        : tab === AddressTransactionsFilterTxType.Receives
        ? stats?.received
        : stats?.sent,
    [stats?.total, tab],
  );

  const hasTabs = !!(
    Number(statsData?.explorerAddressTransactionStats?.stats?.received) &&
    Number(statsData?.explorerAddressTransactionStats?.stats?.sent)
  );

  const onChangeDateHandler = (date: DateTime[]) => {
    if (date.length === 1) {
      setDate([...date, ...date]);
      return;
    }

    setDate(date);
  };

  return (
    <div className="relative">
      {loading && <LoaderOverlay show />}
      {hasTabs && (
        <>
          <div className={cls.wrapper}>
            <Toggle
              initValue={tab}
              items={TABLE_TRANSACTION_TABS()}
              onChange={setTab}
              size="small"
            />
            <Calendar onChange={onChangeDateHandler} />
          </div>
          <Spacer size={12} />
        </>
      )}
      <TableController
        data={items}
        headers={headers()}
        total={Number(total || 0)}
        pageSize={PAGE_SIZE}
        initSort={INIT_SORT}
        initSortBy={INIT_SORT_BY}
        onChangePage={setPage}
        onSort={(sort, sortBy) => setSort({ sort, sortBy })}
      />
    </div>
  );
};
