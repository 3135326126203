import React, { FC, useMemo } from 'react';
import { getNetworkEnum } from '@helpers/address';
import { useExplorerAddressTransactionStatsQuery } from '@apolloGenerated';
import { TransactionsCard } from '@graph-sidebar-module/shared';
import { Typography } from '@rubin-dev/goblin';
import { useTranslation } from 'react-i18next';
import cls from '@graph-sidebar-module/entities/Address/ui/AddressBalanceCard/style.module.scss';

type AddressTransactionsCardProps = {
  address: string;
  network: string;
  isSimpleView?: boolean;
};
export const AddressTransactionsCard: FC<AddressTransactionsCardProps> = ({
  address,
  network,
  isSimpleView,
}) => {
  const { t } = useTranslation();

  const { data, loading } = useExplorerAddressTransactionStatsQuery({
    variables: { network: getNetworkEnum(network || ''), address: address },
  });
  const stats = useMemo(() => data?.explorerAddressTransactionStats?.stats, [data]);

  const sent = Number(stats?.sent || 0);
  const received = Number(stats?.received || 0);
  const total = Number(stats?.total || 0);

  if (isSimpleView) {
    return (
      <div className={cls.wrapper}>
        <div className={cls.group}>
          <Typography variant={'body-14'} color="on-surface-primary-1">
            {t('links.transaction')}
          </Typography>
          <Typography
            variant="body-14"
            color="on-surface-primary-1"
            className={cls.value}
          >
            {Number(total || 0)}
          </Typography>
        </div>
        <div className={cls.group}>
          <Typography variant={'body-14'} color="on-surface-primary-1">
            {t('strings.received')}
          </Typography>
          <Typography variant="body-14" color="success-2" className={cls.value}>
            +{Number(received || 0)}
          </Typography>
        </div>
        <div className={cls.group}>
          <Typography variant={'body-14'} color="on-surface-primary-1">
            {t('strings.sent')}
          </Typography>
          <Typography variant="body-14" color="error-2" className={cls.value}>
            -{Number(sent || 0)}
          </Typography>
        </div>
      </div>
    );
  }

  return (
    <TransactionsCard total={total} sent={sent} received={received} loading={loading} />
  );
};
