import { FC, useMemo, useState } from 'react';
import {
  AddressTransactionsFilterTxType,
  OrderDirection,
  TransactionsBetweenAddress,
} from '@apolloGenerated';
import { LoaderOverlay, Spacer, TableController, Toggle } from '@rubin-dev/goblin';
import {
  renderCellCheckbox,
  renderCellDate,
  renderCellHash,
  renderCellToken,
} from '@shared/ui';
import {
  SortParams,
  TABLE_TRANSACTION_TABS,
  tableStyles,
  Transaction,
  useSidebarData,
  useSidebarEvents,
  useSidebarTrTabs,
} from '@graph-sidebar-module/shared';
import {
  renderCellLineAmount,
  useBetweenTransactions,
} from '@graph-sidebar-module/entities/Line';
import { headers, INIT_SORT, INIT_SORT_BY, PAGE_SIZE } from '../../conts';
import { TableType } from '@graph/types';
import { AddressTable, LinkService } from '@graph/models'; // @TODO: maybe migrate this logic

export const LineTable: FC = () => {
  const { hash, checked, network } = useSidebarData();
  const { onChangeChecked } = useSidebarEvents();
  const { target, source } = useMemo(
    () => LinkService.getAddressesByHash(hash || ','),
    [hash],
  );
  const [tab, setTab] = useSidebarTrTabs();
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState<SortParams>({
    sort: INIT_SORT,
    sortBy: INIT_SORT_BY,
  });
  const params = useMemo(
    () => ({
      to: target,
      from: source,
      direction: AddressTransactionsFilterTxType.All,
      page,
      pageSize: PAGE_SIZE,
      order: sort.sort as OrderDirection,
    }),
    [target, source, page, sort, tab],
  );
  const { data, loading } = useBetweenTransactions(network, params);

  const rowTemplate = ({
    txID,
    amount,
    direction,
    token,
    timestamp,
  }: TransactionsBetweenAddress) => {
    const directionEnum =
      direction === 'receives'
        ? AddressTransactionsFilterTxType.Receives
        : AddressTransactionsFilterTxType.Sent;
    const item = new Transaction(
      {
        txID,
        amount,
        token,
        senderAddr: [{ address: source, network }],
        recvAddr: [{ address: target, network }],
        timestamp,
        direction: directionEnum,
      },
      network,
      TableType.Transaction,
    );
    const checkedItem = AddressTable.getCheckedItem(item.txid, item.type, source, target);
    if (AddressTable.hasChecked(checked, checkedItem)) item.setChecked(true);
    return {
      hashUniq: AddressTable.getCheckedHash(checkedItem),
      checkbox: renderCellCheckbox({
        checked: item.checked,
        onChange: (val: boolean) => onChangeChecked(val, item),
      }),
      hash: renderCellHash({
        hash: item.txid,
        network,
        type: 'transaction',
        longer: true,
        alias: item.txid.slice(0, 8) + '...',
      }),
      timestamp: renderCellDate({ timestamp: item.timestamp! }),
      token: renderCellToken({ token: item.token }),
      amount: renderCellLineAmount({
        target: item.targetAddress?.slice(0, 8) + '...'!,
        source: item.sourceAddress?.slice(0, 8) + '...'!,
        amount: item.amount,
        network,
        type: item.type,
        longer: true,
        className: tableStyles.table__hash,
      }),
    };
  };
  const items = useMemo(() => (data?.items || []).map(rowTemplate), [data, rowTemplate]);

  const total = useMemo(() => {
    if (tab === AddressTransactionsFilterTxType.Receives) return data?.totalReceive;
    else if (tab === AddressTransactionsFilterTxType.Sent) return data?.totalSent;
    return data?.total;
  }, [data?.total, tab]);

  const hasTabs = !!(data?.totalReceive && data?.totalSent);

  return (
    <div className="relative">
      {hasTabs && (
        <>
          <Toggle
            initValue={tab}
            items={TABLE_TRANSACTION_TABS()}
            onChange={setTab}
            size="small"
          />
          <Spacer size={12} />
        </>
      )}
      <TableController
        data={items}
        headers={headers()}
        total={Number(total || 0)}
        pageSize={PAGE_SIZE}
        initSort={INIT_SORT}
        initSortBy={INIT_SORT_BY}
        onChangePage={setPage}
        onSort={(sort, sortBy) => setSort({ sort, sortBy })}
      />
      {loading && <LoaderOverlay show />}
    </div>
  );
};
