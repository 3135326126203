import { useCallback, useMemo, useState } from 'react';
import { Network, useCurrancyQuery } from '@apolloGenerated';
import { NetworkIconEnum } from '@shared/ui';

export function useCurrencyList() {
  const [currentCurrency, setCurrentCurrency] = useState<Network | NetworkIconEnum>(
    NetworkIconEnum.Usdt,
  );

  const { data } = useCurrancyQuery();

  const currencies = useMemo(() => data?.currency || [], [data]);

  const onChangeCurrentCurrency = useCallback((val: Network | NetworkIconEnum) => {
    setCurrentCurrency(val);
  }, []);

  return {
    currencies,
    currentCurrency,
    onChangeCurrentCurrency,
  };
}
