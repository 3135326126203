import i18n from '../../../../../../../i18n/i18n';
import { tableStyles } from '@graph-sidebar-module/shared';

export const PAGE_SIZE = 10;
export const INIT_SORT_BY = 'timestamp';
export const INIT_SORT = 'desc';

export const headers = () => [
  { title: '', value: 'checkbox', width: 20, className: tableStyles.table__checkbox },
  {
    title: `${i18n.t('titles.date')} (UTC)`,
    value: 'timestamp',
    sortable: true,
    width: 140,
  },
  { title: i18n.t('titles.hash'), value: 'hash', width: 140 },
  {
    title: i18n.t('strings.amount'),
    value: 'amount',
    sortable: true,
  },
  {
    title: i18n.t('strings.receivingAddress'),
    value: 'receiving',
  },
  {
    title: i18n.t('strings.counterparty'),
    value: 'counterparty',
  },
  { title: '', value: 'hashUniq', key: true, hide: true },
];
