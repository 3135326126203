import { createGoblin } from '@rubin-dev/goblin';
import { PROJECT_TYPE, ProjectType } from '@shared/libs';

const COLORS: Record<ProjectType, any> = {
  matchsystems: {
    primary1: '#034AB3',
    primary2: '#034AB3',
  },
  plainchain: {
    primary1: '#B92C38',
    primary2: '#222',
  },
  hermespro: {
    primary1: '#1E90FF',
    primary2: '#7928CA',
  },
};
createGoblin({
  lang: {
    es: {
      translation: {
        Calendar: {
          apply: 'Aplicar',
          date: 'Fecha',
        },
      },
    },
    pt: {
      translation: {
        Calendar: {
          apply: 'Aplicar',
          date: 'Data',
        },
      },
    },
  },
  theme: {
    mode: {
      light: {
        colors: {
          'primary-1': COLORS[PROJECT_TYPE].primary1,
          'primary-2': COLORS[PROJECT_TYPE].primary2,
        },
      },
    },
  },
});
