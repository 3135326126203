import { gql } from '@apollo/client';
import {
  blockFragment,
  findAddressByHashNodeFragment,
  mentionFragment,
  nodeFragment,
  riskFragment,
} from './blockchain';

export const clusterFragment = gql`
  fragment ClusterFields on Cluster {
    id
    owner
    riskCategory
    riskVersion
  }
`;
export const addressByTransactionFragment = gql`
  ${clusterFragment}
  fragment AddressByTransactionFields on AddressByTransaction {
    network
    address
    firstAppear
    hexAddress
    summ
    token
    totalIn
    cluster {
      ...ClusterFields
    }
  }
`;
export const addressFragment = gql`
  fragment AddressFields on Address {
    address
    network
    owner
    cluster {
      ...ClusterFields
    }
  }
`;
export const transactionAddressFragment = gql`
  ${addressFragment}
  fragment AddressTransactionFields on AddressTransaction {
    timestamp
    txID
    amount
    direction
    recvAddr {
      ...AddressFields
    }
    senderAddr {
      ...AddressFields
    }
    token
  }
`;
export const transactionFragment = gql`
  fragment TransactionFields on Transaction {
    network
    id
    amount
    timestamp
    from
    to
    token
  }
`;

export const explorerFindBlockByHash = gql`
  ${nodeFragment}
  query explorerFindBlockByHash($network: Network!, $hash: String!) {
    explorerFindBlockByHash(network: $network, hash: $hash) {
      node {
        hash
        height
        timestamp
      }
      errors
    }
  }
`;

export const explorerFindTransactionsInBlockByHash = gql`
  ${nodeFragment}
  query explorerFindTransactionsInBlockByHash(
    $network: Network!
    $hash: String!
    $page: Int!
    $pageSize: Int!
  ) {
    explorerFindTransactionsInBlockByHash(
      network: $network
      hash: $hash
      page: $page
      pageSize: $pageSize
    ) {
      total
      edge {
        ...NodeFields
      }
      errors
    }
  }
`;
export const explorerFindAddressByHash = gql`
  ${findAddressByHashNodeFragment}
  query explorerFindAddressByHash($network: Network!, $address: String!) {
    explorerFindAddressByHash(network: $network, address: $address) {
      node {
        ...FindAddressByHashNodeFields
      }
      errors
    }
  }
`;
export const explorerFindTransactionsByAddress = gql`
  ${transactionAddressFragment}
  query explorerFindTransactionsByAddress(
    $network: Network!
    $filter: AddressTransactionsFilter!
  ) {
    explorerFindTransactionsByAddress(network: $network, filter: $filter) {
      edge {
        ...AddressTransactionFields
      }
      errors
    }
  }
`;
export const explorerFindTransactionsByCluster = gql`
  ${transactionAddressFragment}
  query explorerFindTransactionsByCluster(
    $network: Network!
    $filter: ClusterTransactionsFilter!
  ) {
    explorerFindTransactionsByCluster(network: $network, filter: $filter) {
      edge {
        ...AddressTransactionFields
      }
      errors
    }
  }
`;

export const explorerFindTransactionsByAddressCount = gql`
  query explorerFindTransactionsByAddressCount(
    $network: Network!
    $filter: AddressTransactionsCountFilter!
  ) {
    explorerFindTransactionsByAddressCount(network: $network, filter: $filter) {
      total
      errors
    }
  }
`;

export const explorerFindTransactionsByClusterCount = gql`
  query explorerFindTransactionsByClusterCount(
    $network: Network!
    $filter: ClusterTransactionsCountFilter!
  ) {
    explorerFindTransactionsByClusterCount(network: $network, filter: $filter) {
      total
      errors
    }
  }
`;

export const explorerFindMentionsForAddress = gql`
  ${mentionFragment}
  query explorerFindMentionsForAddress($network: Network!, $address: String!) {
    explorerFindMentionsForAddress(network: $network, address: $address) {
      edge {
        ...MentionFields
      }
      errors
    }
  }
`;

export const explorerRisk = gql`
  ${riskFragment}
  query explorerRisk($network: Network!, $address: String!) {
    explorerRisk(network: $network, address: $address) {
      risk {
        ...RiskFields
      }
      errors
    }
    explorerAddressCategories(network: $network, address: $address) {
      categories {
        number
        name
        value
      }
      errors
    }
  }
`;

export const explorerFindTransactionByHash = gql`
  ${transactionFragment}
  query explorerFindTransactionByHash($network: Network!, $id: String!) {
    explorerFindTransactionByHash(network: $network, id: $id) {
      transaction {
        ...TransactionFields
      }
    }
  }
`;

export const explorerIncomingAddressListByTransaction = gql`
  ${addressByTransactionFragment}
  query explorerIncomingAddressListByTransaction(
    $network: Network!
    $txid: String!
    $page: Int!
    $pageSize: Int!
  ) {
    explorerIncomingAddressListByTransaction(
      network: $network
      txid: $txid
      page: $page
      pageSize: $pageSize
    ) {
      total
      edge {
        ...AddressByTransactionFields
      }
      errors
    }
  }
`;

export const explorerOutcomingAddressListByTransaction = gql`
  ${addressByTransactionFragment}
  query explorerOutcomingAddressListByTransaction(
    $network: Network!
    $txid: String!
    $page: Int!
    $pageSize: Int!
  ) {
    explorerOutcomingAddressListByTransaction(
      network: $network
      txid: $txid
      page: $page
      pageSize: $pageSize
    ) {
      total
      edge {
        ...AddressByTransactionFields
      }
      errors
    }
  }
`;

export const explorerFindBlockByTransaction = gql`
  ${blockFragment}
  query explorerFindBlockByTransaction($network: Network!, $txid: String!) {
    explorerFindBlockByTransaction(network: $network, txid: $txid) {
      node {
        ...BlockchainBlockFields
      }
      errors
    }
  }
`;

export const explorerFindBlockByNumber = gql`
  ${blockFragment}
  query explorerFindBlockByNumber($network: Network!, $height: Int64!) {
    explorerFindBlockByHeight(network: $network, height: $height) {
      node {
        ...BlockchainBlockFields
      }
      errors
    }
  }
`;

export const explorerFindTransactionsInBlock = gql`
  ${nodeFragment}
  query explorerFindTransactionsInBlock(
    $network: Network!
    $height: Int64!
    $page: Int!
    $pageSize: Int!
  ) {
    explorerFindTransactionsInBlock(
      network: $network
      height: $height
      page: $page
      pageSize: $pageSize
    ) {
      total
      edge {
        ...NodeFields
      }
      errors
    }
  }
`;

export const explorerFindTransactionsWithBlock = gql`
  ${nodeFragment}
  ${blockFragment}
  query explorerFindTransactionsWithBlock(
    $network: Network!
    $height: Int64!
    $page: Int!
    $pageSize: Int!
  ) {
    explorerFindBlockByHeight(network: $network, height: $height) {
      node {
        ...BlockchainBlockFields
      }
      errors
    }
    explorerFindTransactionsInBlock(
      network: $network
      height: $height
      page: $page
      pageSize: $pageSize
    ) {
      total
      edge {
        ...NodeFields
      }
      errors
    }
  }
`;

export const explorerCluster = gql`
  ${clusterFragment}
  query explorerCluster($network: Network!, $id: String!) {
    explorerCluster(network: $network, id: $id) {
      cluster {
        ...ClusterFields
      }
    }
    explorerClusterStats(network: $network, id: $id) {
      stats {
        addressCount
        firstTx
        lastTx
        totalAmount
        totalCount
        sentAmount
        sentCount
        receivedAmount
        receivedCount
      }
    }
  }
`;

export const explorerFindWalletAddresses = gql`
  query explorerFindWalletAddresses($network: Network!, $filter: WalletAddressesFilter!) {
    explorerFindWalletAddresses(network: $network, filter: $filter) {
      edge {
        address
        lastActivity
        reported
        balance
        turnover
        totalIn
        totalOut
        transactions
      }
      errors
    }
  }
`;

export const explorerFindWalletAddressesCount = gql`
  query explorerFindWalletAddressesCount(
    $network: Network!
    $filter: WalletAddressesCountFilter!
  ) {
    explorerFindWalletAddressesCount(network: $network, filter: $filter) {
      total
    }
  }
`;

export const explorerClusterForAddress = gql`
  ${clusterFragment}
  query explorerClusterForAddress($network: Network!, $address: String!) {
    explorerClusterForAddress(network: $network, address: $address) {
      cluster {
        ...ClusterFields
      }
    }
  }
`;

export const explorerAddress = gql`
  query explorerAddress($network: Network!, $address: String!) {
    explorerAddress(address: $address, network: $network) {
      network
      address
      owner
    }
  }
`;

export const explorerAddressActivity = gql`
  query explorerAddressActivity($network: Network!, $address: String!) {
    explorerAddressActivity(network: $network, address: $address) {
      activity {
        first
        last
      }
      errors
    }
  }
`;

export const explorerAddressTransactionStats = gql`
  query explorerAddressTransactionStats($network: Network!, $address: String!) {
    explorerAddressTransactionStats(network: $network, address: $address) {
      stats {
        total
        sent
        received
      }
      errors
    }
  }
`;
export const explorerAddressCategories = gql`
  query explorerAddressCategories($network: Network!, $address: String!) {
    explorerAddressCategories(network: $network, address: $address) {
      categories {
        number
        name
        value
      }
      errors
    }
  }
`;

export const explorerAddressChart = gql`
  query explorerAddressChart($network: Network!, $address: String!) {
    explorerAddressChart(network: $network, address: $address) {
      charts {
        resdrt
        rescat
        address
        wid
        received
        owner
        reported
        percent
      }
      errors
    }
  }
`;

export const explorerMarkup = gql`
  query explorerMarkup($network: Network!, $address: String!) {
    explorerMarkup(network: $network, address: $address) {
      errors
      risk {
        risk
      }
      report {
        id
        address
        version
        categoryId
        level
        createdAt
        isPaid
        mentionId
      }
      mention {
        description
        domain
        link
        label
        address
        owner
        risk {
          categoryNumber
          color
        }
        otherAddresses {
          network
          address
        }
        source {
          id
          name
          description
          sourceAddress
          categoryNumber
          version
        }
        reportId
        createdAt
        isPaid
      }
      screenshots
    }
  }
`;

export const explorerFindMention = gql`
  ${mentionFragment}
  query explorerFindMention($network: Network!, $id: ID!) {
    explorerFindMention(network: $network, id: $id) {
      mention {
        ...MentionFields
      }
      screenshots {
        path
        image
      }
    }
  }
`;

export const mentionSourceList = gql`
  query mentionSourceList {
    mentionSourceList {
      mentionSources {
        id
        name
        description
        sourceAddress
        categoryNumber
        version
      }
    }
  }
`;

export const explorerMarkupCreate = gql`
  mutation explorerMarkupCreate($req: ExplorerMarkupCreateInput!) {
    explorerMarkupCreate(req: $req) {
      mention {
        ...MentionFields
      }
    }
  }
`;

export const explorerScreenshotUpload = gql`
  mutation explorerScreenshotUpload($req: ExplorerScreenshotUploadInput!) {
    explorerScreenshotUpload(req: $req) {
      screenshots
    }
  }
`;

export const explorerScreenshotRemove = gql`
  mutation explorerScreenshotRemove($req: ExplorerScreenshotRemoveInput!) {
    explorerScreenshotRemove(req: $req) {
      errors
    }
  }
`;

export const currency = gql`
  query currancy {
    currency
  }
`;
