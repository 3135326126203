import { AddressColor, AddressSize } from '../types/address';
import { Network } from '@apolloGenerated';

export const ACTIVE_COLOR = '#4067CA';
export const ACTIVE_WIDTH = 4;
export const CATEGORY_STROKE_WIDTH = 3;
export const MENTION_STROKE_COLOR = '#FFDA6C';
export const MENTION_STROKE_WIDTH = 3;
export const MENTION_STROKE_OFFSET = 7;
export const SIZES: Record<AddressSize, number> = {
  [AddressSize.SMALL]: 24,
  [AddressSize.MEDIUM]: 32,
  [AddressSize.LARGE]: 40,
};
export const COUNT_TR_SIZE: Record<AddressSize, number> = {
  [AddressSize.SMALL]: 500,
  [AddressSize.MEDIUM]: 2000,
  [AddressSize.LARGE]: Infinity,
};

export const COLORS: Record<AddressColor, string> = {
  [AddressColor.SMALL]: '#ffffff',
  [AddressColor.MEDIUM]: '#E6E6E6',
  [AddressColor.LARGE]: '#394363',
};
export const BALANCE_COLORS: Record<AddressColor, Record<Network, number>> = {
  [AddressColor.SMALL]: {
    [Network.Btc]: 0.00019,
    [Network.Tron]: 58.72,
    [Network.Eth]: 0.0032,
  },
  [AddressColor.MEDIUM]: {
    [Network.Btc]: 0.185,
    [Network.Tron]: 58632.57,
    [Network.Eth]: 3.22,
  },
  [AddressColor.LARGE]: {
    [Network.Btc]: Infinity,
    [Network.Tron]: Infinity,
    [Network.Eth]: Infinity,
  },
};
