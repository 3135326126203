import matchsystemLogo from '@images/logo/matchsystem/logo.png';
import matchsystemDenseLogo from '@images/logo/matchsystem/logo-dense.svg';
import logoRubinImg from '@images/logo/plainchain/logo.png';
import logoHermesproImg from '@images/logo/hermespro/hermespro.png';

import React, { FC, useMemo } from 'react';
import cx from 'classnames';
import { PROJECT_TYPE } from '@shared/libs';
import { PropsWithNativeTypes } from '@rubin-dev/goblin';
import styles from './styles.module.scss';

export type LogoProps = {
  dense?: boolean;
};

export const Logo: FC<PropsWithNativeTypes<LogoProps, HTMLImageElement>> = ({
  dense,
  className,
  ...rest
}) => {
  const src = useMemo(() => {
    switch (PROJECT_TYPE) {
      case 'matchsystems':
        return dense ? matchsystemDenseLogo : matchsystemLogo;
      case 'plainchain':
        return logoRubinImg;
      case 'hermespro':
        return logoHermesproImg;
    }
  }, [dense]);

  return <img src={src} className={cx(styles.logo, className)} alt="logo" {...rest} />;
};
